`use strict`;

module.exports = {
  groups: [
    'Raumvergabe',
    'Kundentermine'
  ],
  exclude: {
    group: {
      0: {
        dates: [{ title: 'Test', begin: '2022-12-24 00:00:00', end: '2022-12-25 01:00:00' }],
        pause: {
          1: [['16:00', '16:30']],
          2: [['14:00', '14:45']]
        }
      },
      1: {
        dates: [{ title: 'dssdds', begin: '2022-11-23 16:00:00', end: '2022-11-26 18:00:00' }, { title: 'Date 2', begin: '2022-11-24 00:00:00', end: '2022-12-24 01:00:00' }],
        pause: {
          1: [['12:00', '12:30']],
          2: [['18:00', '18:45']]
        }
      }
    }
  },
  ressources: [
  {
    start: '',
    end: '',
    status: 'active',
    groups: [0],
    details: {
      title: 'Besprechungsraum 1',
      shortDescription: 'Großer Besprechungsraum',
      description: 'Buchung des großen Besprechungsraums',
      number: 'Raum #542',
      group: [0],
      image: '',
      video: ''
    },
    booking: {
      max_applicants: {
        datepicker: [5, 5, 5, 5, 5, 5, 5],
        eventpicker: [100]
      },
      password: '',
      type: 'datepicker',
      include: {
        dates: {
          0: ['07:00', '18:00', '60'],
          1: ['07:00', '18:00', '60'],
          2: ['07:00', '18:00', '60'],
          3: ['07:00', '18:00', '60'],
          4: ['07:00', '12:00', '60'],
        },
        events: []
      }
    },
    contact: {
      name: 'Peter Müller',
      email: 'peter@mueller.de',
      location: ''
    },
    permissions: {
      type: 'email',
      settings: ['']
    },
    form: {

    },
    verify: true,
    reminder: true
  },
  {
    start: '',
    end: '',
    status: 'active',
    groups: [0],
    details: {
      title: 'Inhouse Workshop',
      shortDescription: 'Großer Besprechungsraum',
      description: 'Buchung des großen Besprechungsraums',
      number: 'Raum #542',
      group: [1],
      image: '',
      video: ''
    },
    booking: {
      max_applicants: {
        datepicker: [5, 5, 5, 5, 5, 5, 5],
        eventpicker: [100]
      },
      password: '',
      type: 'datepicker',
      include: {
        dates: {
          0: ['07:00', '18:00', '60'],
          1: ['07:00', '18:00', '60'],
          2: ['07:00', '18:00', '60'],
          3: ['07:00', '18:00', '60'],
          4: ['07:00', '12:00', '60'],
        },
        events: []
      }
    },
    contact: {
      name: 'Michael Mayer',
      email: 'mayer@mueller.de',
      location: 'Außentermin beim Kunden'
    },
    permissions: {
      type: 'email',
      settings: ['']
    },
    form: {

    },
    verify: true,
    reminder: true
  },
  {
    start: '',
    end: '',
    status: 'active',
    groups: [0],
    details: {
      title: 'Sommerfest mit Kunden',
      shortDescription: 'Großer Besprechungsraum',
      description: 'Buchung des großen Besprechungsraums',
      number: 'Raum #542',
      group: [1],
      image: '',
      video: ''
    },
    booking: {
      max_applicants: {
        datepicker: [5, 5, 5, 5, 5, 5, 5],
        eventpicker: [100]
      },
      password: '',
      type: 'eventpicker',
      include: {
        dates: {
          0: ['07:00', '18:00', '60'],
          1: ['07:00', '18:00', '60'],
          2: ['07:00', '18:00', '60'],
          3: ['07:00', '18:00', '60'],
          4: ['07:00', '12:00', '60'],
        },
        events: []
      }
    },
    contact: {
      name: 'Michael Mayer',
      email: 'mayer@mueller.de',
      location: 'Berliner Platz 1234'
    },
    permissions: {
      type: 'email',
      settings: ['']
    },
    form: {

    },
    verify: true,
    reminder: true
  }]
}
