`use strict`;

const demoData = require('./defaultDataset.js')

const Parts = {
  0: require('./parts/part_0'),
  1: require('./parts/part_1'),
  2: require('./parts/part_2')
}

module.exports = function (lang) {
  this.lang = lang

  this.titles = [
    'Kalender-Kategorien',
    'Termin-Kalender',
    'Buchungszeiten',
    'Pausezeiten',
    'Einstellungen',
    'Formular-Editor',
    'Erinnerungen'
  ],
  this.form = [
    new Parts[0](this.lang).data,
    new Parts[1](this.lang).data,
    new Parts[2](this.lang).data
  ],

  this.data = demoData
}
