<template>
  <div>
    <a v-if="!editTitle" href="#" @click.prevent="setEditTitle">
      <slot />
      <b-flix-icon icon="pencil" />
    </a>
    <b-flix-p v-else class="openMore">
      <b-flix-input :callback="setValue" :value="newText" />
      <b-flix-button variant="contained-green" :callback="setSave">
        <b-flix-icon icon="check" />
      </b-flix-button>
    </b-flix-p>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    text: {
      type: String,
      default () { return '' }
    }
  },
  data () {
    return {
      editTitle: false,
      newText: JSON.parse(JSON.stringify(this.text))
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setSave () {
      this.editTitle = false
      this.$emit('save', this.newText)
    },
    setEditTitle () {
      this.editTitle = true
    },
    setValue (val) {
      this.newText = val
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
