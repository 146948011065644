<template>
  <div>
    <slot />
    <b-flix-p v-if="!checkTime()">
      <b-flix-alert variant="danger">
        Bitte überprüfen!
      </b-flix-alert>
    </b-flix-p>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    start: {
      type: String
    },
    end: {
      type: String
    }
  },
  data () {
    return {
      startDate: this.createDate(this.start),
      endDate: this.createDate(this.end),
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    checkTime () {
      if (this.startDate.getTime() > this.endDate.getTime()) {
        return false
      }
      return true
    },
    createDate (hour) {
      const date = new Date()
      date.setHours(hour.split(':')[0] * 1)
      date.setMinutes(hour.split(':')[1] * 1)
      return date
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
