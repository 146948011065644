<template>
  <div class="flex flex-wrap flex-gap-5">
    <span v-for="(tag, index) in tags" :key="tag">
      <b-flix-button v-if="newSelected.includes(index)" variant="contained-blue" :callback="function () { removeTag(index) }"><b-flix-icon icon="check" /> {{ tag }}</b-flix-button>
      <b-flix-button v-else variant="text" :callback="function () { setTag(index) }">{{ tag }}</b-flix-button>
    </span>
  </div>
</template>
<script>
export default {
  name: 'tagList',
  components: {},
  props: {
    tags: {
      type: Array
    },
    selected: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      newSelected: JSON.parse(JSON.stringify(this.selected))
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setTag (index) {
      this.newSelected.push(index)
      this.$emit('add', index)
    },
    removeTag (index) {
      this.newSelected.splice(this.newSelected.indexOf(index), 1)
      this.$emit('remove', index)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
