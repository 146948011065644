<template>
  <div>
    <div class="flex flex-center flex-start flex-gap-5" :key="update">
      <datePicker :start="newDate1.getFullYear() + '-' + (newDate1.getMonth() + 1) + '-' + newDate1.getDate()" @save="setSaveDate1" />
      <timePicker :start="newDate1.getHours() + ':' + newDate1.getMinutes()" @save="setSaveTime1" />
      <b-flix-icon v-if="newDate1.getTime() !== newDate2.getTime()" icon="arrow-right" />
      <datePicker v-if="newDate1.getTime() !== newDate2.getTime()" :start="newDate2.getFullYear() + '-' + (newDate2.getMonth() + 1) + '-' + newDate2.getDate()" @save="setSaveDate2" class="openMore" />
      <timePicker v-if="newDate1.getTime() !== newDate2.getTime()" :start="newDate2.getHours() + ':' + newDate2.getMinutes()" @save="setSaveTime2" class="openMore" />
      <b-flix-button v-if="newDate1.getTime() === newDate2.getTime()" variant="text" :callback="setEnd"><b-flix-icon icon="circle-empty-plus" /> Terminende</b-flix-button>
      <b-flix-button v-if="newDate1.getTime() !== newDate2.getTime()" variant="text" :callback="removeEnd"><b-flix-icon icon="circle-empty-minus" /> Terminende</b-flix-button>
    </div>
    <b-flix-p v-if="newDate2.getTime() < newDate1.getTime()" >
      <b-flix-alert variant="danger">Terminende muss nach Terminbeginn liegen!</b-flix-alert>
    </b-flix-p>
  </div>
</template>
<script>
const TimeFunctions = require('@/plugins/time.js')
export default {
  components: {
    timePicker () { return import('@/components/timePicker') },
    datePicker () { return import('@/components/datePicker') }
  },
  props: {
    date1: {
      type: String
    },
    date2: {
      type: String
    }
  },
  data () {
    return {
      edit1: false,
      edit2: false,
      newDate1: this.createDate(this.date1),
      newDate2: this.createDate(this.date2),
      update: 0
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    removeEnd () {
      this.newDate2 = new TimeFunctions().createDate(new TimeFunctions().createDateString(this.newDate1))
      this.$emit('save', [this.newDate1, this.newDate2])
      this.update++
    },
    setEnd () {
      this.newDate2.setHours(this.newDate2.getHours() + 1)
      this.$emit('save', [this.newDate1, this.newDate2])
      this.update++
    },
    setSaveDate1 (date) {
      this.setAutoupdate2(this.createDate(date + ' ' + this.newDate1.getHours() + ':' + this.newDate1.getMinutes() ))
      this.$emit('save', [this.newDate1, this.newDate2])
    },
    setSaveDate2 (date) {
      this.newDate2 = this.createDate(date + ' ' + this.newDate2.getHours() + ':' + this.newDate2.getMinutes() )
      this.$emit('save', [this.newDate1, this.newDate2])
    },
    setSaveTime1 (date) {
      this.setAutoupdate2(this.createDate(this.newDate1.getFullYear() + '-' + (this.newDate1.getMonth() * 1 + 1) + '-' + this.newDate1.getDate() + ' ' + date))
      this.$emit('save', [this.newDate1, this.newDate2])
    },
    setSaveTime2 (date) {
      this.newDate2 = this.createDate(this.newDate2.getFullYear() + '-' + (this.newDate2.getMonth() * 1 + 1) + '-' + this.newDate2.getDate() + ' ' + date)
      this.$emit('save', [this.newDate1, this.newDate2])
    },
    setAutoupdate2 (newDate) {
      if (this.newDate1.getTime() === this.newDate2.getTime()) {
        this.newDate2 = new TimeFunctions().createDate(new TimeFunctions().createDateString(newDate))
      }
      this.newDate1 = newDate
    },
    getNullsBefore (int) {
      return new TimeFunctions().getNullsBefore(int)
    },
    createDate (date) {
      return new TimeFunctions().createDate(date)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
