<template>
  <ul>
    <li v-for="(item, index) in items.titles" :key="index" :class="{'active': state > index, 'current': state === index}">
      <a href="#" @click.prevent="$emit('callback', index)">
        <span v-if="state < index">{{ index + 1 }}.</span>
        <b-flix-icon v-else-if="state === index" icon="wrench" />
        <b-flix-icon v-else icon="check" />
        {{ item }}
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'BottomMenu',
  components: {},
  props: {
    items: {
      type: Object
    },
    state: {
      type: Number
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  ul
    list-style-type: none
    list-style: none
    padding: 0
    margin: 0
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100px
    flex-wrap: wrap
    li
      flex: 1
      position: relative
      padding: 25px 0
      a
        border-top-width: 4px
        border-top-style: solid
        border-top-color: #2e424a
        background-color: #f0f2f4
        position: absolute
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        text-decoration: none
        box-sizing: border-box
        font-family: "Poppins", sans-serif
        font-size: 1rem
        font-weight: 400
        line-height: 1.1rem
        color: black
        display: flex
        gap: 5px
        align-items: center
        justify-content: center
        font-size: .8rem
        font-weight: bold
        transition: transform .3s ease-out
        transform-origin: bottom center
      &.active
        a
          border-color: #42bd84
      &.current
        a
          transform: scale(1.1)
          z-index: 2
          border-color: #0e9556
          background-color: #a2b2b9
          box-shadow: 0px -10px 10px -8px rgba(0,0,0,.3)
    @media (max-width: 700px)
      height: inherit
      position: relative
      li
        flex: 1 1 100%
</style>
