<template>
  <div>
    PART_5
    <saveButton />
  </div>
</template>
<script>
export default {
  components: {
    saveButton () { return import('@/components/saveButton') }
  },
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
