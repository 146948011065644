<template>
  <b-flix-button variant="contained-green" :callback="setSave"><b-flix-icon icon="circle-check" /> Jetzt speichern</b-flix-button>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setSave () {
      this.$emit('save')
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
