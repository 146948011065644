<template>
  <div>
    <b-flix-p :key="updateTime">
      <b-flix-alert variant="info">
        {{ getDates(newRessources[index].booking.include.dates) }}
      </b-flix-alert>
    </b-flix-p>

    <!--- Datepicker -->
    <moreInfos label="Buchungszeiten anpassen">
      <b-flix-p>
        <bookingTimeRange :data="newRessources[index]" @save="function (val) { setSaveStartEnd(index, val) }" />
      </b-flix-p>
      <div v-for="date in Array(7).keys()" :key="date" class="mb-40">
        <b-flix-p>
          <b-flix-checkbox :values="{1: item[2].values[date]}" :value="getOpen(index, date)" :callback="function (val) { setActive(index, date, val) }" />
        </b-flix-p>

        <b-flix-p v-if="getOpen(index, date)" :key="updateTime">
          <checkTime class="flex flex-center flex-gap-5 flex-start" :start="newRessources[index].booking.include.dates[date][0]" :end="newRessources[index].booking.include.dates[date][1]">
            <timePicker :start="newRessources[index].booking.include.dates[date][0]" @save="function (val) { setStart(index, date, val) }" />
            <b-flix-icon icon="minus" />
            <timePicker :start="newRessources[index].booking.include.dates[date][1]" @save="function (val) { setEnd(index, date, val) }" />
          </checkTime>
        </b-flix-p>
        <b-flix-p>
          maximal
        </b-flix-p>

      </div>
    </moreInfos>
    <!--- /Datepicker -->
  </div>
</template>
<script>
export default {
  components: {
    timePicker () { return import('@/components/timePicker') },
    checkTime () { return import('@/components/checkTime') },
    moreInfos () { return import('@/components/moreInfo') },
    bookingTimeRange () { return import('@/components/bookingTimeRange') }
  },
  props: {
    ressources: {
      type: Array
    },
    item: {
      type: Array
    },
    index: {
      type: Number
    }
  },
  data () {
    return {
      newRessources: JSON.parse(JSON.stringify(this.ressources)),
      updateTime: 0
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setSaveStartEnd (index, val) {
      this.newRessources[index] = val
      this.$emit('save', this.newRessources)
    },
    setActive (index, date, val) {
      if (!val.length) {
        delete this.newRessources[index].booking.include.dates[date]
      } else {
        this.newRessources[index].booking.include.dates[date] = ['08:00', '18:00', '60']
      }
      this.updateTime++
      this.$emit('save', this.newRessources)
    },
    getDates (dates) {
      const dateString = []
      const items = this.item

      Object.keys(dates).forEach(function (key) {
        dateString.push(items[2].values[key][0] + items[2].values[key][1] + '.')
      })

      if (!dateString.length) {
        return items[2].error
      }
      return dateString.join(', ')
    },
    getOpen (index, weekday) {
      if (Object.keys(this.newRessources[index].booking.include.dates).includes(weekday + '')) {
        return 1
      }
      return ''
    },
    setStart (index, date, val) {
      this.newRessources[index].booking.include.dates[date][0] = val
      this.updateTime++
      this.$emit('save', this.newRessources)
    },
    setEnd (index, date, val) {
      this.newRessources[index].booking.include.dates[date][1] = val
      this.updateTime++
      this.$emit('save', this.newRessources)
    },
  }
}
</script>
<style lang="sass" scoped>
</style>
