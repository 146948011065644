<template>
  <div v-if="newDates.length">
    <div class="text-right">
      <b-flix-button variant="contained-green" :callback="setClean">Alle {{ newDates.length }} Einträge entfernen</b-flix-button>
    </div>
    <addNew v-for="(date, i) in newDates" :key="i" :item="i" :all="newDates.length" @newItem="function (val) { setNewItem(val) }" @removeItem="function (val) { setRemoveItem(val) }" class="gray-border-bottom">
      <div :key="update">
        <editText :text="date.title" @save="function (val) { setTitle(i, val) }">
          <b-flix-h2>{{ date.title }}</b-flix-h2>
        </editText>
        <b-flix-p>
          <dateSelector :date1="date.begin" :date2="date.end" @save="function(val) { setSaveItem(i, val) }"/>
        </b-flix-p>
      </div>
    </addNew>
    <b-flix-p>
      <b-flix-button v-if="!changed.length" variant="text-icon" :callback="setSave">zurück</b-flix-button>
      <b-flix-button v-else variant="text-icon" :callback="setSave">{{ changed.length }} Änderungen übernehmen</b-flix-button>
    </b-flix-p>
  </div>
</template>
<script>
const TimeFunctions = require('@/plugins/time.js')
export default {
  name: 'PauseList',
  components: {
    dateSelector () { return import('@/components/dateSelector') },
    addNew () { return import('@/components/addNew') },
    editText () { return import('@/components/editText') }
  },
  props: {
    dates: {
      type: Array
    }
  },
  data () {
    return {
      newDates: JSON.parse(JSON.stringify(this.dates)),
      update: 0,
      changed: []
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setClean () {
      this.newDates = []
      this.setSave()
    },
    setSave () {
      this.$emit('save', this.newDates)
    },
    setTitle (i, val) {
      this.newDates[i].title = val
      this.update++
    },
    setNewItem () {
      const old = JSON.parse(JSON.stringify(this.newDates[0]))
      this.newDates.push(old)
      this.changed.push(this.newDates.length - 1)
      this.update++
    },
    setRemoveItem (val) {
      this.newDates.splice(val, 1)
      this.update++
    },
    setSaveItem (i, val) {
      this.newDates[i] = {title: this.newDates[i].title, begin: new TimeFunctions().createDateString(val[0]), end: new TimeFunctions().createDateString(val[1])}
      this.changed.push(i)
      this.update++
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
