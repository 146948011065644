<template>
  <div>
    <div :key="update">
      <div class="overlayContainer">
        <b-flix-calendarpicker :value="currentMonth" :values="getValues" style="flex: 1; background-color: transparent;" class="background" :class="{blur: edit}" :settings="{ shadow: false }" @save="setEdit">
        </b-flix-calendarpicker>
        <div class="overlay" v-if="edit">
          <a class="close" href="#" variant="text" @click.prevent="function () { edit = false; editTime = false; editList = false }"><b-flix-icon icon="times" /></a>
            <div>
              <div>{{ getWeekday(edit.date) }}</div>
              <b-flix-h1>{{ getFormatedDate(edit.date) }}</b-flix-h1>
            </div>
            <div v-if="!editTime && !editList" class="flex flex-center flex-gap-5"  :key="edit.date">
              <b-flix-button v-if="!edit.state" variant="contained-blue" :callback="editDay">bearbeiten</b-flix-button>
              <b-flix-button variant="contained-blue" :callback="blockDate">Ganzen Tag auswählen</b-flix-button>
              <b-flix-button variant="contained-blue" :callback="blockPause">Uhrzeit auswählen</b-flix-button>
              <b-flix-button variant="contained-blue" :callback="blockHoliday">Zeitraum von hier bis ...</b-flix-button>
            </div>
            <div v-else>
              <component :is="editMode" :time="editTime" @save="setPause" />
            </div>
        </div>
      </div>
    </div>
    <div v-if="edit && editList">
      <dayList :dates="editList.dates" @save="saveDay" />
    </div>
  </div>
</template>
<script>
const TimeFunctions = require('@/plugins/time.js')
export default {
  name: 'EventpickerPause',
  components: {
    pause () { return import('./pause') },
    holidays () { return import('./holidays') },
    dayList () { return import('./list') }
  },
  props: {
    dates: {
      type: Array
    }
  },
  data () {
    return {
      update: 0,
      currentMonth: new TimeFunctions().createDateString(new Date()),
      edit: 0,
      newDates: JSON.parse(JSON.stringify(this.dates)),
      editTime: false,
      editMode: false,
      editList: false
    }
  },
  computed: {
    getValues () {
      const dates = {}
      this.newDates.forEach(function (d) {
        let begin = new TimeFunctions().createDate(d.begin)
        let end = new TimeFunctions().createDate(d.end)
        let daysBetween = ((end.getTime() - begin.getTime()) / 1000 ) / (60 * 60 * 24)
        for (let i = 0; i < daysBetween; i++) {
          const generatedDate = new TimeFunctions().createDateString(begin).split(' ')[0]
          dates[generatedDate]= 1
          begin.setDate(begin.getDate() + 1)
        }
      })
      return Object.keys(dates)
    }
  },
  mounted () {

  },
  methods: {
    saveDay (val) {
      const indexes = this.editList.indexes.sort().reverse()
      
      for (let i = 0; i < indexes.length; i++) {
        this.newDates.splice(indexes[i], 1)
      }
      for (let i = 0; i < val.length; i++) {
        this.newDates.push(val[i])
      }
      this.editTime = false
      this.edit = false
      this.editMode = false
      this.editList = false
      this.update += 1
      this.$emit('save', this.newDates)
    },
    editDay () {
      const dates = {dates: [], indexes: []}
      const reference = {
        begin: new TimeFunctions().createDate(this.edit.date + ' 00:00:00'),
        end: new TimeFunctions().createDate(this.edit.date + ' 23:59:59')
      }
      this.newDates.forEach(function (d, index) {
        let begin = new TimeFunctions().createDate(d.begin)
        let end = new TimeFunctions().createDate(d.end)
        let daysBetween = ((end.getTime() - begin.getTime()) / 1000 ) / (60 * 60 * 24)
        for (let i = 0; i < daysBetween; i++) {
          if (end.getTime() >= reference.begin.getTime() && begin.getTime() <= reference.end.getTime()) {
            dates.dates.push(d)
            dates.indexes.push(index)
            return true
          }
          begin.setDate(begin.getDate() + 1)
        }
      })
      this.editList = dates
    },
    setPause (val) {
      this.newDates.push(val)
      this.editTime = false
      this.edit = false
      this.editMode = false
      this.update += 1
      this.$emit('save', this.newDates)
    },
    blockHoliday () {
      const Tf = new TimeFunctions()
      Tf.date = Tf.createDate(this.edit.date + ' 23:59:59')
      Tf.date.setDate(Tf.date.getDate() + 13)
      this.editTime = { title: 'Mehrtägige Veranstaltung', begin: this.edit.date + ' 00:00:00', end: Tf.createDateString(Tf.date) }
      this.editMode = 'holidays'
    },
    blockPause () {
      this.editTime = { title: 'Veranstaltung', begin: this.edit.date + ' 12:00:00', end: this.edit.date + ' 13:00:00' }
      this.editMode = 'pause'
    },
    blockDate () {
      this.newDates.push({ title: 'Ganztagsveranstaltung', begin: this.edit.date + ' 00:00:00', end: this.edit.date + ' 23:59:59' })
      this.edit = false
      this.update += 1
      this.$emit('save', this.newDates)
    },
    getWeekday (date) {
      const Tf = new TimeFunctions()
      Tf.date = Tf.createDate(date + ' 00:00:00')
      return Tf.getFullWeekday()
    },
    getFormatedDate (date) {
      const time = date.split('-')
      return time[2] + '.' + time[1] + '.' + time[0]
    },
    setEdit (val) {
      this.edit = val
      this.currentMonth = val.date
      this.update += 1
    }
  }
}
</script>
<style lang="sass" scoped>
  .blur
    filter: blur(8px) grayscale(1) opacity(.5)
  .overlayContainer
    position: relative
    .background
      position: relative
    .overlay
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: rgba(0,0,0,0)
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      gap: 10px

      a.close
        color: black
        text-decoration: 0
        font-size: 3rem
        position: absolute
        right: 20px
        top: 20px
</style>
