<template>
  <div>
    <editText :text="newTime.title" @save="function (val) { newTime.title = val }">
      <b-flix-h2>{{ newTime.title }}</b-flix-h2>
    </editText>
    <b-flix-p>
      <dateSelector :date1="newTime.begin" :date2="newTime.end" @save="setDate" />
    </b-flix-p>
    <b-flix-button variant="contained-green" :callback="setSave">Speichern</b-flix-button>
  </div>
</template>
<script>
const TimeFunctions = require('@/plugins/time.js')
export default {
  name: 'AddHolidays',
  components: {
    editText () { return import('@/components/editText') },
    dateSelector () { return import('@/components/dateSelector') }
  },
  props: {
    time: {
      type: Object
    }
  },
  data () {
    return {
      newTime: JSON.parse(JSON.stringify(this.time)),
      update: 0
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setSave () {
      this.$emit('save', this.newTime)
    },
    setDate (val) {
      this.newTime.begin = new TimeFunctions().createDateString(val[0])
      this.newTime.end = new TimeFunctions().createDateString(val[1])
      this.update++
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
