<template>
  <div class="openMore">
    <b-flix-p>
      <slot />
    </b-flix-p>
    <b-flix-p class="flex flex-center flex-gap-5" style="justify-content: flex-end">
      <b-flix-button v-if="all != 1" variant="text" :callback="removeItem"><b-flix-icon icon="circle-empty-minus" /></b-flix-button>
      <b-flix-button v-if="item + 1 === all" variant="text" :callback="addItem"><b-flix-icon icon="circle-empty-plus" /></b-flix-button>
    </b-flix-p>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    item: {
      type: Number
    },
    all: {
      type: Number
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    addItem () {
      this.$emit('newItem')
    },
    removeItem () {
      this.$emit('removeItem', this.item)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
