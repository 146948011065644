<template>
  <div>
    <b-flix-button variant="text-icon" :callback="setOpen">{{ label }}</b-flix-button>
    <b-flix-p v-if="open" class="openMore">
      <slot />
    </b-flix-p>
  </div>
</template>
<script>
export default {
  name: 'MoreInfo',
  components: {},
  props: {
    label: {
      type: String,
      default () { return 'weitere Infos' }
    }
  },
  data () {
    return {
      open: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setOpen () {
      this.open = !this.open
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
