<template>
  <wrapper class="mainWrapper">
    <mainTitle style="margin-bottom: 5px; margin-top: 20px">{{ items.titles[state] }}</mainTitle>
    <component :is="'part_' + state" :item="items.form[state]" :data="items.data" @setSave="setSave" />
    <div class="bottomMenu">
      <slot name="bottomMenu" />
    </div>
  </wrapper>
</template>
<script>
export default {
  name: 'RessourcesView',
  components: {
    wrapper () { return import('@/components/wrapper') },
    mainTitle () { return import('@/components/title') },
    part_0 () { return import('@/views/parts/part_0') },
    part_1 () { return import('@/views/parts/part_1') },
    part_2 () { return import('@/views/parts/part_2') },
    part_3 () { return import('@/views/parts/part_3') },
    part_4 () { return import('@/views/parts/part_4') },
    part_5 () { return import('@/views/parts/part_5') }
  },
  props: {
    state: {
      type: Number
    },
    items: {
      type: Object
    }
  },
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setSave (data) {
      this.$emit('setSave', data)
    }
  }
}
</script>
<style lang="sass" scoped>
  .mainWrapper
    position: relative
    padding-bottom: 100px
    // overflow: hidden
  .bottomMenu
    position: absolute
    bottom: 0
    left: 0
    width: 100%
  @media (max-width: 700px)
    .mainWrapper
      padding-bottom: 50px
    .bottomMenu
      height: inherit
      position: relative
</style>
