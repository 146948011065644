<template>
  <div class="striped-container">
    <div class="stickyButton">
      <b-flix-p v-if="error">
        <b-flix-alert class="mt-20" variant="danger">{{ error }}</b-flix-alert>
      </b-flix-p>
      <saveButton v-else @save="function () { $emit('setSave', { exclude: newData.exclude }) }" />
    </div>
    <div v-for="(ressource, index) in ressources" :key="index" :item="index" :all="ressources.length" class="striped pt-40 pb-40">

      <b-flix-p>Termin-Kategorie #{{ index + 1 }}</b-flix-p>
      <b-flix-h2>{{ ressource }}</b-flix-h2>
      <b-flix-p>
        <moreInfo :label="'regelmäßige Pausen anpassen (' + Object.keys(data.exclude.group[index].pause).length + ')'">
          <div class="gray-border-bottom">
            <b-flix-p>
              <div class="flex flex-center flex-start">
                <timePicker start="18:00" /> <b-flix-icon icon="arrow-right" /> <timePicker start="19:00" />
              </div>
            </b-flix-p>
            <b-flix-p>
              <b-flix-checkbox :values="weekdays" variant="inline" />
            </b-flix-p>
          </div>
          <b-flix-p>
            <b-flix-button variant="text"><b-flix-icon icon="circle-empty-plus" /> neue regelmäßige Pause</b-flix-button>
          </b-flix-p>
        </moreInfo>
      </b-flix-p>
      <b-flix-p>
        <moreInfo :label="'Pausen zu exakten Terminen anpassen (' + newData.exclude.group[index].dates.length + ')'" >
          <pause :dates="newData.exclude.group[index].dates" @save="function (val) { setPause(index, val) }" />
        </moreInfo>
      </b-flix-p>
    </div>
  </div>
</template>
<script>
const TimeFunctions = require('@/plugins/time.js')
export default {
  components: {
    saveButton () { return import('@/components/saveButton') },
    timePicker () { return import('@/components/timePicker') },
    moreInfo () { return import('@/components/moreInfo') },
    pause () { return import('@/components/pause/index') }
  },
  props: {
    item: {
      type: Array
    },
    data: {
      type: Object
    }
  },
  data () {
    return {
      weekdays: new TimeFunctions().weekdays,
      ressources: JSON.parse(JSON.stringify(this.data.groups)),
      newData: JSON.parse(JSON.stringify(this.data)),
      error: false,
      update: 0
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setPause (index, val) {
      this.newData.exclude.group[index].dates = val
      this.update++
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
