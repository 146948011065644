<template>
  <div class="striped-container" :key="update">

    <div class="stickyButton">
      <b-flix-p v-if="error">
        <b-flix-alert class="mt-20" variant="danger">Bitte Pflichtfelder (*) hinterlegen.</b-flix-alert>
      </b-flix-p>
      <saveButton v-else @save="function () { $emit('setSave', { ressources: ressources }) }" />
    </div>

    <div v-for="(ressource, index) in ressources" :key="index" :item="index" :all="ressources.length" class="striped pt-40 pb-40">
      <b-flix-h2>
        {{ ressource.details.title }}
      </b-flix-h2>

      <b-flix-p>
        <b-flix-label :label="item[0].title" />
      </b-flix-p>

      <b-flix-p>
        <b-flix-radio :values="item[0].values" :value="ressource.booking.type" :callback="function (val) { setChangeBookingType(index, val) }" />
      </b-flix-p>

      <datePicker v-if="ressource.booking.type === 'datepicker'" :ressources="ressources" :item="item" :index="index" @save="setSaveDatepicker" />
      <eventPicker v-else :ressources="ressources" :item="item" :index="index" @save="setSaveDatepicker" />

    </div>

  </div>
</template>
<script>
export default {
  components: {
    saveButton () { return import('@/components/saveButton') },
    datePicker () { return import('@/components/time/datePicker') },
    eventPicker () { return import('@/components/time/eventPicker') }
  },
  props: {
    item: {
      type: Array
    },
    data: {
      type: Object
    }
  },
  data () {
    return {
      ressources: JSON.parse(JSON.stringify(this.data.ressources)),
      error: false,
      update: 0,
      updateTime: 0
    }
  },
  computed: {

  },
  mounted () {
    this.isError()
  },
  methods: {
    setChangeBookingType (index, val) {
      this.ressources[index].booking.type = Object.keys(val)[0]
      // this.updateTime++
    },
    setSaveDatepicker (ressources) {
      this.ressources = ressources
      this.updateTime++
    },
    isError () {
      let error = false
      this.ressources.forEach(function (ressource) {
        if (!ressource.details.title) {
          error = true
        }
      })
      this.error = error
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
