<template>
  <div class="striped-container" :key="update">

    <div class="stickyButton">
      <b-flix-p v-if="error">
        <b-flix-alert class="mt-20" variant="danger">Bitte Pflichtfelder (*) hinterlegen.</b-flix-alert>
      </b-flix-p>
      <saveButton v-else @save="function () { $emit('setSave', { groups: ressources, exclude: pause }) }" />
    </div>

    <addNew v-for="(ressource, index) in ressources" :key="index" :item="index" :all="ressources.length" @newItem="setNewItem" @removeItem="setRemoveItem" class="striped pt-40">
      <b-flix-h2>
        Kategorie {{ index + 1 }}
      </b-flix-h2>

      <b-flix-p>
        <b-flix-input :value="ressource" :type="item[0].subtype" :label="item[0].title" :required="item[0].required" :info="item[0].info" :callback="function (val) { updateItem(index, val) }" />
      </b-flix-p>

    </addNew>

  </div>
</template>
<script>
export default {
  components: {
    addNew () { return import('@/components/addNew') },
    saveButton () { return import('@/components/saveButton') }
  },
  props: {
    item: {
      type: Array
    },
    data: {
      type: Object
    }
  },
  data () {
    return {
      ressources: JSON.parse(JSON.stringify(this.data.groups)),
      pause: JSON.parse(JSON.stringify(this.data.exclude)),
      error: false,
      update: 0
    }
  },
  computed: {

  },
  mounted () {
    this.isError()
  },
  methods: {
    isError () {
      let error = false
      this.ressources.forEach (function (res) {
        if (!res) {
          error = true
        }
      })
      this.error = error
    },
    updateItem (index, val) {
      this.ressources[index] = val
      this.pause.group[index] = {
        dates: [],
        pause: {}
      }
      this.isError()
    },
    setNewItem () {
      this.ressources.push('')
      this.isError()
    },
    setRemoveItem (index) {
      this.ressources.splice(index, 1)
      this.update++
      this.isError()
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
