import Vue from 'vue'
import App from './App.vue'
import calendarItems from 'bookingflix_calendar_items'
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'

require('@/plugins/bf-framework.js')

Vue.config.productionTip = false
Vue.use(calendarItems)
Vue.use(vueCustomElement);

Vue.customElement('flix-ressources-quickstart', App)
