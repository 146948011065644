<template>
  <div>
    <div class="stickyButton">
      <b-flix-p v-if="error">
        <b-flix-alert class="mt-20" variant="danger">{{ error }}</b-flix-alert>
      </b-flix-p>
      <saveButton v-else @save="function () { $emit('setSave', { exclude: newData.exclude }) }" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    saveButton () { return import('@/components/saveButton') }
  },
  props: {},
  data () {
    return {
      error: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
