<template>
  <div class="striped-container" :key="update">
    <div class="stickyButton">
      <b-flix-p v-if="error">
        <b-flix-alert class="mt-20" variant="danger">Bitte Pflichtfelder (*) hinterlegen.</b-flix-alert>
      </b-flix-p>
      <saveButton v-else @save="function () { $emit('setSave', { ressources: ressources }) }" />
    </div>

    <addNew v-for="(ressource, index) in ressources" :key="index" :item="index" :all="ressources.length" @newItem="setNewItem" @removeItem="setRemoveItem" class="striped pt-40">
      <b-flix-p>#{{ index + 1 }}</b-flix-p>
      <div class="flex" style="justify-content: space-between">
        <div>

          <editText :text="ressource.details.title" @save="function (val) { setValue(index, 'title', val) }">
            <b-flix-h2>
              {{ ressource.details.title }}
            </b-flix-h2>
          </editText>
          <b-flix-p>
            <b-flix-label label="Zugeordnete Terminkategorien" />
            <ressourcesTags :selected="ressource.details.group" :tags="data.groups" @add="function (tag) { addTag(index, tag) }" @remove="function (tag) { removeTag(index, tag) }"/>
          </b-flix-p>
        </div>
        <div>
          <imageUpload :label="ressource.details.title" />
        </div>
      </div>
      <moreInfos>
        <b-flix-p>
          <b-flix-input :callback="function (val) { setValue(index, 'shortDescription', val) }" :value="ressource.details.shortDescription" :type="item[1].subtype" :label="item[1].title" :required="item[1].required" :info="item[1].info" />
        </b-flix-p>
        <b-flix-p>
          <b-flix-input :callback="function (val) { setValue(index, 'description', val) }" :value="ressource.details.description" :type="item[2].subtype" :label="item[2].title" :required="item[2].required" :info="item[2].info" />
        </b-flix-p>
        <b-flix-p>
          <b-flix-input :callback="function (val) { setValue(index, 'number', val) }" :value="ressource.details.number" :type="item[3].subtype" :label="item[3].title" :required="item[3].required" :info="item[3].info" />
        </b-flix-p>
        <b-flix-p>
          <b-flix-input :callback="function (val) { setValue(index, 'password', val) }" :value="ressource.booking.password" :type="item[4].subtype" :label="item[4].title" :required="item[4].required" :info="item[4].info" />
        </b-flix-p>
      </moreInfos>

      <moreInfos label="Kontakt / Location">
        <b-flix-p>
          <b-flix-input :callback="function (val) { setContact(index, 'name', val) }" :value="ressource.contact.name" :type="item[5].subtype" :label="item[5].title" :info="item[5].info" />
        </b-flix-p>
        <b-flix-p>
          <b-flix-input :callback="function (val) { setContact(index, 'email', val) }" :value="ressource.contact.email" :type="item[6].subtype" :label="item[6].title" :info="item[6].info" />
        </b-flix-p>
        <b-flix-p>
          <b-flix-input :callback="function (val) { setContact(index, 'locaiton', val) }" :value="ressource.contact.location" :type="item[7].subtype" :label="item[7].title" :info="item[7].info" />
        </b-flix-p>
      </moreInfos>
      <moreInfos label="Design">
        <b-flix-p>
          <b-flix-colorpicker  label="Zugeordnete Farbe" />
        </b-flix-p>
      </moreInfos>
    </addNew>
  </div>
</template>
<script>
export default {
  components: {
    addNew () { return import('@/components/addNew') },
    moreInfos () { return import('@/components/moreInfo') },
    saveButton () { return import('@/components/saveButton') },
    ressourcesTags () { return import('@/components/tags') },
    imageUpload () { return import('@/components/imageUpload') },
    editText () { return import('@/components/editText') }
  },
  props: {
    item: {
      type: Array
    },
    data: {
      type: Object
    }
  },
  data () {
    return {
      ressources: JSON.parse(JSON.stringify(this.data.ressources)),
      error: false,
      update: 0,
      editTitle: []
    }
  },
  computed: {

  },
  mounted () {
    this.isError()
  },
  methods: {
    isError () {
      let error = false
      this.ressources.forEach(function (ressource) {
        if (!ressource.details.title) {
          error = true
        }
      })
      this.error = error
    },
    setValue (index, id, value) {
      this.ressources[index].details[id] = value
      this.isError()
    },
    setContact (index, id, value) {
      this.ressources[index].contact[id] = value
      this.isError()
    },
    setNewItem () {
      const old = JSON.parse(JSON.stringify(this.ressources[0]))
      const replace = ['description', 'image', 'number', 'shortDescription', 'title', 'video']
      replace.forEach(function (rubber) { old.details[rubber] = '' })
      old.details.group = [0]
      old.details.title = this.item[0].title
      this.ressources.push(old)
      this.isError()
    },
    setRemoveItem (index) {
      this.ressources.splice(index, 1)
      this.update++
      this.isError()
    },
    addTag (index, tag) {
      this.ressources[index].details.group.push(tag)
    },
    removeTag (index, tag) {
      this.ressources[index].details.group.splice(this.ressources[index].details.group.indexOf(tag), 1)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
