<template>
  <div>
    <b-flix-button v-if="!edit" variant="contained-blue" :callback="setEdit"><b-flix-icon icon="clock" /> {{ getNullsBefore(startDate[0]) }}:{{ getNullsBefore(startDate[1]) }}</b-flix-button>
    <div v-else class="flex flex-center flex-start flex-gap-10">
      <b-flix-select :value="getNullsBefore(startDate[0])" :callback="setHour">
        <option v-for="time in Array(24).keys()" :key="time" :value="getNullsBefore(time)">{{ getNullsBefore(time) }}</option>
      </b-flix-select>
      <b-flix-select :value="getNullsBefore(startDate[1])" :callback="setMinutes">
        <option v-for="time in Array(4).keys()" :key="time" :value="getNullsBefore(time * 15)">{{ getNullsBefore(time * 15) }}</option>
        <option :value="getNullsBefore(59)">{{ getNullsBefore(59) }}</option>
      </b-flix-select>
      <b-flix-button class="flex flex-center" variant="contained-blue" :callback="setSave"><b-flix-icon icon="check" /></b-flix-button>
    </div>
  </div>
</template>
<script>
const TimeFunctions = require('@/plugins/time.js')
export default {
  name: 'TimePicker',
  components: {},
  props: {
    start: {
      type: String
    }
  },
  data () {
    return {
      startDate: this.getStartDate(),
      edit: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    getNullsBefore (date) {
      return new TimeFunctions().getNullsBefore(date)
    },
    setHour (val) {
      this.startDate[0] = new TimeFunctions().getNullsBefore(val)
    },
    setMinutes (val) {
      this.startDate[1] = new TimeFunctions().getNullsBefore(val)
    },
    setSave () {
      this.$emit('save', this.startDate.join(':'))
      this.setEdit()
    },
    setEdit () {
      this.edit = !this.edit
    },
    getStartDate () {
      let startDate = this.start.split(':')
      this.getNullsBefore(startDate[0])
      this.getNullsBefore(startDate[1])
      return startDate
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
