`use strict`;

module.exports = function () {
  this.createDate = function (dateString) {
    const date = new Date()
    const d = dateString.split(' ')
    d[0] = d[0].split('-')
    d[1] = d[1].split(':')
    date.setDate(1)
    date.setMonth(d[0][1] * 1 - 1)
    date.setFullYear(d[0][0])
    date.setDate(d[0][2])
    date.setHours(d[1][0])
    date.setMinutes(d[1][1])
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
  }
  this.months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ]
  this.weekdays = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag',
  ]

  this.date = new Date()

  this.getMonth = function () {
    const mon = this.date.getMonth() * 1 + 1
    return { number: mon, month: this.months[mon - 1] }
  }
  this.getDay = function () {
    return this.date.getDate()
  }
  this.getYear = function () {
    return this.date.getFullYear()
  }
  this.getLengthOfMonth = function () {
    return new Date(
      this.date.getFullYear(),
      this.date.getMonth() * 1 + 1,
      0,
      0,
      0,
      0,
      0
    ).getDate()
  }
  this.getFullWeekday = function () {
    let weekday = this.date.getDay()
    weekday--
    if (weekday < 0) {
      weekday = 6
    }
    return this.weekdays[weekday]
  }
  this.getWeekday = function () {
    const wd = this.getFullWeekday()
    return wd[0] + wd[1] + '.'
  }
  this.getNullsBefore = function (value) {
    value *= 1
    if (value <= 9) {
      return '0' + value
    }
    return value
  }
  this.createDateString = function (date) {
    return this.getNullsBefore(date.getFullYear()) + '-' + this.getNullsBefore(date.getMonth() * 1 + 1) + '-' + this.getNullsBefore(date.getDate()) + ' ' + this.getNullsBefore(date.getHours()) + ':' + this.getNullsBefore(date.getMinutes()) + ':00'
  }
}
