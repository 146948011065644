`use strict`;

module.exports = function (lang) {
  this.lang = lang
  this.data = [
    {
      title: 'Bezeichnung',
      type: 'input',
      subtype: 'text',
      info: 'z.B. Räume, Leihgeräte etc.',
      required: true
    }
  ]
}
