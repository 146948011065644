`use strict`;

module.exports = function (lang) {
  this.lang = lang
  this.data = [{
      title: 'Bezeichnung',
      type: 'input',
      subtype: 'text',
      info: 'Nur diese Anzeige ist Pflichtangabe. Alles weitere ist optional anzugeben.',
      required: true
    },
    {
      title: 'Kurzbeschreibung',
      type: 'input',
      subtype: 'text',
      required: false
    },
    {
      title: 'Ausführliche Beschreibung',
      type: 'input',
      subtype: 'textarea',
      info: '',
      required: false
    },
    {
      title: 'Nummer',
      type: 'input',
      subtype: 'number',
      info: '',
      required: false
    },
    {
      title: 'Buchungs-Passwort',
      type: 'input',
      subtype: 'text',
      info: 'Nur wer dieses kennt, kann die Buchung vornehmen',
      required: false
    },
    {
      title: 'Name',
      type: 'input',
      subtype: 'text'
    },
    {
      title: 'Email',
      type: 'input',
      subtype: 'email'
    },
    {
      title: 'Location',
      type: 'input',
      subtype: 'textarea'
    }
  ]
}
