<template>
  <div>
    <b-flix-button v-if="!edit" variant="contained-blue" :callback="setEdit"><b-flix-icon icon="calendar" /> {{ getWeekday(startDate[2]) }} {{ getNullsBefore(startDate[2]) }}.{{ getNullsBefore(startDate[1]) }}.{{ getNullsBefore(startDate[0]) }}</b-flix-button>
    <div v-else class="flex flex-center flex-start flex-gap-10">
      <b-flix-select :value="getNullsBefore(startDate[2])" :callback="setDate">
        <option v-for="time in Array(getLengthOfMonth()).keys()" :key="time" :value="getNullsBefore(time + 1)">{{ getWeekday(time + 1) }} {{ getNullsBefore(time + 1) }}</option>
      </b-flix-select>
      <b-flix-select :key="update" :value="getNullsBefore(startDate[1])" :callback="setMonth">
        <option v-for="time in Array(12).keys()" :key="time" :value="getNullsBefore(time + 1)">{{ getMonth(time + 1) }}</option>
      </b-flix-select>
      <b-flix-select :value="getNullsBefore(startDate[0])" :callback="setYear">
        <option v-for="time in Array(5).keys()" :key="time" :value="getNullsBefore(currYear + time)">{{ getNullsBefore(currYear + time) }}</option>
      </b-flix-select>
      <b-flix-button class="flex flex-center" variant="contained-blue" :callback="setSave"><b-flix-icon icon="check" /></b-flix-button>
    </div>
  </div>
</template>
<script>
const TimeFunctions = require('@/plugins/time.js')
export default {
  name: 'TimePicker',
  components: {},
  props: {
    start: {
      type: String
    }
  },
  data () {
    return {
      startDate: this.getStartDate(),
      edit: false,
      currYear: new Date().getFullYear(),
      update: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    getWeekday (i) {
      const TF = new TimeFunctions()
      TF.date = TF.createDate(this.startDate[0] + '-' + this.startDate[1] + '-' + i + ' 00:00:00')

      return TF.getWeekday()
    },
    getMonth (index) {
      const TF = new TimeFunctions()
      TF.date = TF.createDate(this.startDate[0] + '-' + index + '-' + this.startDate[2] + ' 00:00:00')
      return TF.getMonth().month
    },
    getLengthOfMonth () {
      const TF = new TimeFunctions()
      TF.date = TF.createDate(this.startDate[0] + '-' + this.startDate[1] + '-' + this.startDate[2] + ' 00:00:00')
      return TF.getLengthOfMonth()
    },
    getNullsBefore (date) {
      return new TimeFunctions().getNullsBefore(date)
    },
    setYear (val) {
      this.startDate[0] = new TimeFunctions().getNullsBefore(val)
      this.update++
    },
    setMonth (val) {
      this.startDate[1] = new TimeFunctions().getNullsBefore(val)
      this.update++
    },
    setDate (val) {
      this.startDate[2] = new TimeFunctions().getNullsBefore(val)
    },
    setSave () {
      this.$emit('save', this.startDate.join('-'))
      this.setEdit()
    },
    setEdit () {
      this.edit = !this.edit
    },
    getStartDate () {
      let startDate = this.start.split('-')
      startDate[0] = this.getNullsBefore(startDate[0])
      startDate[1] = this.getNullsBefore(startDate[1])
      startDate[2] = this.getNullsBefore(startDate[2])
      return startDate
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
