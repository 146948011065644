`use strict`;

module.exports = function (lang) {
  this.lang = lang
  this.data = [{
      title: '... ist buchbar',
      type: 'option',
      values: {'datepicker': 'regelmäßig', 'eventpicker': 'zu exakten Terminen'},
      info: 'Nur diese Anzeige ist Pflichtangabe. Alles weitere ist optional anzugeben.',
      required: true
    },
    {
      title: 'Maximal mögliche Buchungen',
      type: 'input',
      subtype: 'text',
      required: false
    },
    {
      title: 'Tage',
      values: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
      error: 'Bitte Buchungszeiten auswählen'
    },
    {
      title: 'Nummer',
      type: 'input',
      subtype: 'number',
      info: '',
      required: false
    },
    {
      title: 'Buchungs-Passwort',
      type: 'input',
      subtype: 'text',
      info: 'Nur wer dieses kennt, kann die Buchung vornehmen',
      required: false
    }
  ]
}
