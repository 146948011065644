<template>
  <div class="image flex flex-center">
    <span class="letter">
      {{ label[0] }}
    </span>
    <a href="#" class="icon">
      <b-flix-icon icon="camera" />
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    label: {
      type: String
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  .image
    width: 120px
    height: 120px
    border: 2px solid #1e6da7
    color: #1e6da7
    border-radius: 50%
    font-size: 5rem
    margin-bottom: 10px
    .icon
      text-decoration: none
      color: #1e6da7
      display: none
    .letter
      text-transform: uppercase
    &:hover
      .letter
        display: none
      .icon
        display: block
</style>
