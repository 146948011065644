<template>
  <div>
    <b-flix-p :key="updateTime">
      <b-flix-alert variant="info">
        {{ getEventsLength() }} Termin(e)
      </b-flix-alert>
    </b-flix-p>

    <moreInfos label="Termine anpassen">
      <div>
      <pause :dates="newRessources[index].booking.include.events" @save="function (val) { setSave(val) }" />
      </div>
    </moreInfos>

  </div>
</template>
<script>
const TimeFunctions = require('@/plugins/time.js')
export default {
  components: {
    moreInfos () { return import('@/components/moreInfo') },
    pause () { return import('@/components/pause/index.vue') }
  },
  props: {
    ressources: {
      type: Array
    },
    item: {
      type: Array
    },
    index: {
      type: Number
    }
  },
  data () {
    return {
      newRessources: JSON.parse(JSON.stringify(this.ressources)),
      updateTime: 0
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setSave (val) {
      this.newRessources[this.index].booking.include.events = val
      this.$emit('save', this.newRessources)
    },
    getEventsLength () {
      return this.newRessources[this.index].booking.include.events.length
    },
    setMaxApplicants () {
      this.$emit('save', this.newRessources)
    },
    setNewItem () {
      const oldData = JSON.parse(JSON.stringify(this.newRessources[this.index].booking.include.events[0]))
      const length = this.getEventsLength()
      this.newRessources[this.index].booking.max_applicants.eventpicker[length] = 20
      this.newRessources[this.index].booking.include.events[length] = oldData
      this.updateTime++
      this.$emit('save', this.newRessources)
    },
    setRemoveItem (index) {
      this.newRessources[this.index].booking.include.events.splice(index, 1)
      this.updateTime++
      this.$emit('save', this.newRessources)
    },
    setChangeDate (index, value) {
      const TF = new TimeFunctions()
      this.newRessources[this.index].booking.include.events[index][0] = TF.createDateString(value[0])
      this.newRessources[this.index].booking.include.events[index][1] = TF.createDateString(value[1])
      this.$emit('save', this.newRessources)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
