<template>
  <b-flix-card>
    <slot />
  </b-flix-card>
</template>
<script>
export default {
  name: 'mainWrapper',
  components: {},
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
