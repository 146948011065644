<template>
  <div>
    <editText :text="newTime.title" @save="function (val) { newTime.title = val }">
      <b-flix-h2>{{ newTime.title }}</b-flix-h2>
    </editText>
    <b-flix-p>
      <checkTime :key="update" :start="newTime.begin.split(' ')[1]" :end="newTime.end.split(' ')[1]">
        <div class="flex flex-center flex-start flex-gap-10">
          <timePicker :start="newTime.begin.split(' ')[1]" @save="setStart" /> <b-flix-icon icon="minus" /> <timePicker :start="newTime.end.split(' ')[1]" @save="setEnd" />
        </div>
      </checkTime>
    </b-flix-p>
    <b-flix-button variant="contained-green" :callback="setSave">Speichern</b-flix-button>
  </div>
</template>
<script>
export default {
  name: 'AddPause',
  components: {
    editText () { return import('@/components/editText') },
    timePicker () { return import('@/components/timePicker') },
    checkTime () { return import('@/components/checkTime') }
  },
  props: {
    time: {
      type: Object
    }
  },
  data () {
    return {
      newTime: JSON.parse(JSON.stringify(this.time)),
      update: 0
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setSave () {
      this.$emit('save', this.newTime)
    },
    setStart (val) {
      this.newTime.begin = this.newTime.begin.split(' ')[0] + ' ' + val
      this.update++
    },
    setEnd (val) {
      this.newTime.end = this.newTime.end.split(' ')[0] + ' ' + val
      this.update++
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
