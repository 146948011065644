<template>
  <div style="max-width: 1200px; margin: 0 auto; margin-top: 50px;">
  <component :is="'mainView'" :items="items" :state="state" @setSave="setSave">
    <bottomMenu slot="bottomMenu" :items="items" :state="state" @callback="setState" />
  </component>
  </div>
</template>
<script>
import Content from '@/plugins/content.js'
export default {
  components: {
    mainView () { return import('@/views/main') },
    bottomMenu () { return import('@/components/bottomMenu') }
  },
  props: {
    language: {
      type: String,
      default () { return 'de' }
    }
  },
  data () {
    return {
      items: new Content(this.language),
      state: 0
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setState (state) {
      this.state = state
    },
    setSave (data) {
      this.items.data = Object.assign(this.items.data, data)
      this.state++
    }
  }
}
</script>
<style lang="sass">
  @import '/node_modules/bookingflix_framework/dist/lib/src/bflix.css'
  @import '@/assets/style.sass'
</style>
