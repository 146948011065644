<template>
  <div class="flex flex-center flex-start flex-gap-5">
    Buchung ist an folgenden Wochentagen möglich von 
    <b-flix-button v-if="!start" variant="contained-blue" :callback="setStart"><b-flix-icon icon="calendar" /> sofort</b-flix-button>
    <div v-else class="flex flex-start flex-gap-5">
      <dateSelector :start="start" /> 
      <b-flix-button variant="contained-blue" :callback="removeStart"><b-flix-icon icon="times" /></b-flix-button>
    </div>
    bis 
    <b-flix-button v-if="!end" variant="contained-blue" :callback="setEnd"><b-flix-icon icon="calendar" /> unbegrenzt</b-flix-button>
    <div v-else class="flex flex-start flex-gap-5">
      <dateSelector :start="end" /> 
      <b-flix-button variant="contained-blue" :callback="removeEnd"><b-flix-icon icon="times" /></b-flix-button>
    </div>
  </div>
</template>

<script>
const TimeFunctions = require('@/plugins/time')
export default {
  name: 'BookingTimeRange',
  components: {
    dateSelector () { return import('@/components/datePicker') }
  },
  props: {
    data: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      start: '',
      end: ''
    }
  },
  mounted () {
    this.start = JSON.parse(JSON.stringify(this.data.start))
    this.end = JSON.parse(JSON.stringify(this.data.end))
  },
  methods: {
    setSave () {
      this.$emit('save', Object.assign(this.data, { start: this.start, end: this.end }))
    },
    setStart () {
      const TF = new TimeFunctions()
      TF.date = new Date()
      
      this.start = TF.createDateString(TF.date).split(' ')[0]
      this.setSave()
    },
    setEnd () {
      const TF = new TimeFunctions()
      TF.date = new Date()
      TF.date.setDate(TF.date.getDate() + 30)
      this.end = TF.createDateString(TF.date).split(' ')[0]
      this.setSave()
    },
    removeStart () {
      this.start = ''
      this.setSave()
    },
    removeEnd () {
      this.end = ''
      this.setSave()
    }
  }
}
</script>

<style>

</style>